import React from 'react'

import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'

function NotFoundPage() {
  return (
    <Layout>
      <SEO
        title="404: Not Found"
        image={`/og-images/404.png`}
      />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
